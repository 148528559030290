var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-main'),_c('section',{staticClass:"about-us"},[_c('div',{staticClass:"about-us__box"},[_c('div',{staticClass:"about-us__left"},[_c('h1',{staticClass:"about-us__left-h1"},[_vm._v("Quienes somos")]),_c('h2',{staticClass:"about-us__left-h2"},[_vm._v("#TeamAppears")]),_c('p',{staticClass:"about-us__left-p"},[_vm._v(" Somos una propuesta empresarial a la medida de los nuevos tiempos, tiempos difíciles y que requieren de la adaptación a la era digital. ")]),_c('div',{staticClass:"about-us__left-services"},[_c('v-tooltip',{staticClass:"about-us__left-tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"about-us__left-services-icon-social",attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v("mdi-facebook")])]}}])},[_c('span',[_vm._v("Facebook")])]),_c('v-tooltip',{staticClass:"about-us__left-tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"about-us__left-services-icon-social",attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v("mdi-instagram")])]}}])},[_c('span',[_vm._v("Instagram")])]),_c('v-tooltip',{staticClass:"about-us__left-tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"about-us__left-services-icon-social",attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v("mdi-linkedin")])]}}])},[_c('span',[_vm._v("LinkedIn")])]),_c('v-tooltip',{staticClass:"about-us__left-tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"about-us__left-services-icon-social",attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v("mdi-twitter")])]}}])},[_c('span',[_vm._v("Twitter")])]),_c('v-tooltip',{staticClass:"about-us__left-tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"about-us__left-services-icon-social",attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v("mdi-youtube")])]}}])},[_c('span',[_vm._v("YouTube")])]),_c('v-tooltip',{staticClass:"about-us__left-tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"about-us__left-services-icon-social",attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v("mdi-youtube")])]}}])},[_c('span',[_vm._v("TikTok")])])],1)]),_vm._m(0)])]),_c('team'),_vm._m(1),_c('scheduling',{attrs:{"background":"white"}}),_c('footer-main')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-us__right"},[_c('div',{staticClass:"about-us__right-video-box",staticStyle:{"height":"325px"},attrs:{"itemscope":"","itemtype":"https://schema.org/VideoGallery"}},[_c('img',{attrs:{"src":"/statics/img/shapes/moon.svg","alt":"Imagen temporal load"}}),_c('img',{attrs:{"src":"/statics/img/shapes/space.svg","alt":"Imagen temporal load"}}),_c('img',{attrs:{"src":"/statics/img/shapes/rocket.svg","alt":"Imagen temporal load"}}),_c('iframe',{attrs:{"itemprop":"video","width":"100%","height":"100%","src":"https://www.youtube.com/embed/S0j3l2u_W5k","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"objectives"},[_c('h2',{staticClass:"objectives__h2"},[_vm._v("Objetivos")]),_c('div',{staticClass:"objectives__box"},[_c('div',{staticClass:"objectives__left"},[_c('div',{staticClass:"objectives__left-video-box",staticStyle:{"height":"325px"},attrs:{"itemscope":"","itemtype":"https://schema.org/VideoGallery"}},[_c('img',{attrs:{"src":"/statics/img/shapes/moon.svg","alt":"Imagen temporal load"}}),_c('img',{attrs:{"src":"/statics/img/shapes/space.svg","alt":"Imagen temporal load"}}),_c('img',{attrs:{"src":"/statics/img/shapes/rocket.svg","alt":"Imagen temporal load"}}),_c('iframe',{attrs:{"itemprop":"video","width":"100%","height":"100%","src":"https://www.youtube.com/embed/egOGOrvXZgM","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])]),_c('div',{staticClass:"objectives__right"},[_c('img',{attrs:{"src":"/statics/img/shapes/objetivos.png","alt":"Imagen de objetivos"}})])])])}]

export { render, staticRenderFns }