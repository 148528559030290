<template>
  <div>
    <header-main></header-main>
    <section class="about-us">
      <div class="about-us__box">
        <div class="about-us__left">
          <h1 class="about-us__left-h1">Quienes somos</h1>
          <h2 class="about-us__left-h2">#TeamAppears</h2>
          <p class="about-us__left-p">
            Somos una propuesta empresarial a la medida de los nuevos tiempos,
            tiempos difíciles y que requieren de la adaptación a la era digital.
          </p>
          <div class="about-us__left-services">
            <v-tooltip bottom color="purple" class="about-us__left-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="about-us__left-services-icon-social"
                  color="purple"
                  >mdi-facebook</v-icon
                >
              </template>
              <span>Facebook</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="about-us__left-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="about-us__left-services-icon-social"
                  color="purple"
                  >mdi-instagram</v-icon
                >
              </template>
              <span>Instagram</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="about-us__left-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="about-us__left-services-icon-social"
                  color="purple"
                  >mdi-linkedin</v-icon
                >
              </template>
              <span>LinkedIn</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="about-us__left-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="about-us__left-services-icon-social"
                  color="purple"
                  >mdi-twitter</v-icon
                >
              </template>
              <span>Twitter</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="about-us__left-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="about-us__left-services-icon-social"
                  color="purple"
                  >mdi-youtube</v-icon
                >
              </template>
              <span>YouTube</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="about-us__left-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="about-us__left-services-icon-social"
                  color="purple"
                  >mdi-youtube</v-icon
                >
              </template>
              <span>TikTok</span>
            </v-tooltip>
          </div>
        </div>
        <div class="about-us__right">
          <div
            class="about-us__right-video-box"
            style="height: 325px"
            itemscope
            itemtype="https://schema.org/VideoGallery"
          >
            <img
              src="/statics/img/shapes/moon.svg"
              alt="Imagen temporal load"
            />
            <img
              src="/statics/img/shapes/space.svg"
              alt="Imagen temporal load"
            />
            <img
              src="/statics/img/shapes/rocket.svg"
              alt="Imagen temporal load"
            />
            <iframe
              itemprop="video"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/S0j3l2u_W5k"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
    <team></team>
    <section class="objectives">
      <h2 class="objectives__h2">Objetivos</h2>
      <div class="objectives__box">
        <div class="objectives__left">
          <div
            class="objectives__left-video-box"
            style="height: 325px"
            itemscope
            itemtype="https://schema.org/VideoGallery"
          >
            <img
              src="/statics/img/shapes/moon.svg"
              alt="Imagen temporal load"
            />
            <img
              src="/statics/img/shapes/space.svg"
              alt="Imagen temporal load"
            />
            <img
              src="/statics/img/shapes/rocket.svg"
              alt="Imagen temporal load"
            />
            <iframe
              itemprop="video"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/egOGOrvXZgM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="objectives__right">
          <!-- <h1 class="objectives__right-h1">Quienes somos</h1> -->
          <img
            src="/statics/img/shapes/objetivos.png"
            alt="Imagen de objetivos"
          />
        </div>
      </div>
    </section>
    <scheduling background="white"></scheduling>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";
import Scheduling from "@/components/Scheduling.vue";
import Team from "@/components/Team.vue";
import FooterMain from "@/components/Footer.vue";

export default {
  name: "AboutUs",
  components: {
    HeaderMain,
    Scheduling,
    Team,
    FooterMain,
  },
  data() {
    return {
      swiperOptionAbout: {
        pagination: {
          el: ".swiper-pagination-about-us",
          clickable: true,
        },
      },
    };
  },
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about-us {
  background: var(--white-secondary);
  width: 100%;
}

.about-us__box {
  display: grid;
  margin: auto;
  max-width: 1200px;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  /* width: 100%; */
  padding: 80px 30px;
}
.about-us__left {
  text-align: left;
  /* background: blue; */
}
.about-us__left-h1 {
  color: var(--primary-opt);
  font-size: 55px;
  font-weight: 800;
  line-height: 0;
}
.about-us__left-h2 {
  color: var(--black);
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
}
.about-us__left-h2 span {
  color: var(--positive);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.about-us__left-p {
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}
.about-us__left-services-icon-social {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  border: 2.5px solid var(--primary);
  border-radius: 15px;
}
.about-us__left-tooltip {
  background: var(--primary) !important;
}
.about-us__right-video-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  background: var(--secondary);
  border: 5px solid var(--primary);
  border-radius: 20px;
}
.about-us__right-video-box img {
  height: 200px;
  position: absolute;
}
.about-us__right-video-box iframe {
  border-radius: 20px;
  padding: 5px;
  z-index: 3;
}
@media screen and (max-width: 991px) {
  .about-us__box {
    grid-template-columns: 100%;
    padding: 30px 30px 60px 30px;
  }
  .about-us__right-video-box {
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .about-us__left-h1 {
    font-size: 35px;
    line-height: 0.5;
  }
  .about-us__left-h2,
  .about-us__left-h2 span {
    font-size: 22px;
  }
}

.objectives {
  margin-top: 50px;
  display: grid;
  align-items: center;
  background: var(--white);
  width: 100%;
  /* height: 85vh; */
  /* background: var(--white); */
  background-image: url(/statics/img/shapes/shape-back3.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: inherit;
}

.objectives__box {
  display: grid;
  margin: auto;
  max-width: 1200px;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  /* width: 100%; */
  padding: 0 30px 80px 30px;
}
.objectives__right {
  /* text-align: left; */
  /* padding-left: 50px; */
  /* background: blue; */
}
.objectives__right img {
  width: 90%;
  float: right;
}

.objectives__h2 {
  padding-top: 50px;
  color: var(--black);
  font-size: 45px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
}
.objectives__left-video-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  background: var(--primary);
  border: 5px solid var(--secondary);
  border-radius: 20px;
}
.objectives__left-video-box img {
  height: 200px;
  position: absolute;
}
.objectives__left-video-box iframe {
  border-radius: 20px;
  padding: 5px;
  z-index: 3;
}
@media screen and (max-width: 991px) {
  .objectives__box {
    grid-template-columns: 100%;
    padding: 30px 30px 60px 30px;
  }
  .objectives__left-video-box {
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .objectives__right-h1 {
    font-size: 35px;
    line-height: 0.5;
  }
  .objectives__right-h2,
  .objectives__right-h2 span {
    font-size: 22px;
  }
}
</style>
